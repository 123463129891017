import { FormatOptions } from './format.types'
import { formatStorage } from './formatStorage'

export type WeekdayFormat = 'short' | 'long'

/**
 * Returns array of weekday numbers: [0, 1, 2, 3, 4, 5, 6]
 *
 * @param weekStart 每周的第一天，start of week， 0 代表周日，以此类推
 * @returns array of weekdays, starting with sunday by default
 */
export function weekdays(weekStart?: number) {
  const firstDayOfWeek = formatStorage.get()?.firstDayOfWeek || 0
  weekStart = weekStart ?? firstDayOfWeek
  return Array(7)
    .fill(null)
    .map((_, day) => (day + weekStart!) % 7)
}

// By default starts on Sunday (Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday)
/**
 * Returns array of weekdays, starting with sunday by default
 *
 * @param locale 'en-US', 'fr-FR', etc.
 * @param weekStart 每周的第一天，start of week， 0 代表周日，以此类推
 * @param format 'Sunday' or 'Sun'
 * @returns array of weekdays, starting with sunday by default
 */
export function weekdayNames(weekStart?: number, options?: FormatOptions) {
  const formatOptions = {
    ...formatStorage.get(),
    ...options,
  }
  return weekdays(weekStart).map((day) => nameOfDay(day, formatOptions))
}

/**
 *
 * @param locale 'en-US', 'fr-FR', etc.
 * @param day day of the week, 0 for Sunday, 1 for Monday, etc.
 * @param format long or short
 * @returns name of the day
 */
export function nameOfDay(day: number, options?: FormatOptions) {
  const formatOptions = {
    ...formatStorage.get(),
    ...options,
  }
  const format = formatOptions?.weekdayFormat || 'long'
  return new Intl.DateTimeFormat(formatOptions?.locale, {
    weekday: format,
  }).format(new Date(1970, 0, day + 4))
}
