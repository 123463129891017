import { FormatOptions } from './format.types'

interface FormatStorage {
  get: () => FormatOptions | undefined
  set: (format: FormatOptions) => void
  setTimeZone: (timeZone: string) => void
  getTimeZone: () => string | undefined
  setLocale: (locale: string) => void
  getLocale: () => string | undefined
  run: <T>(format: FormatOptions, callback: () => T) => T
}

let formatStorage: FormatStorage

if (typeof window === 'undefined') {
  // Node.js environment
  //@ts-ignore
  const { AsyncLocalStorage } = require('async_hooks')
  const asyncLocalStorage = new AsyncLocalStorage()

  formatStorage = {
    get: () => asyncLocalStorage.getStore(),
    set: (format: FormatOptions) => asyncLocalStorage.enterWith(format),
    setTimeZone: (timeZone: string) =>
      asyncLocalStorage.enterWith({
        ...asyncLocalStorage.getStore(),
        timeZone,
      }),
    getTimeZone: () => asyncLocalStorage.getStore()?.timeZone,
    setLocale: (locale: string) =>
      asyncLocalStorage.enterWith({ ...asyncLocalStorage.getStore(), locale }),
    getLocale: () => asyncLocalStorage.getStore()?.locale,
    run: (format: FormatOptions, callback: () => any) =>
      asyncLocalStorage.run(format, callback),
  }
} else {
  // Browser environment
  let currentFormat: FormatOptions | undefined

  formatStorage = {
    get: () => currentFormat,
    set: (format: FormatOptions) => {
      currentFormat = format
    },
    setTimeZone: (timeZone: string) => {
      currentFormat = { ...currentFormat, timeZone }
    },
    getTimeZone: () => currentFormat?.timeZone,
    setLocale: (locale: string) => {
      currentFormat = { ...currentFormat, locale }
    },
    getLocale: () => currentFormat?.locale,
    run: (format: FormatOptions, callback: () => any) => {
      const previousFormat = currentFormat
      currentFormat = format
      try {
        return callback()
      } finally {
        currentFormat = previousFormat
      }
    },
  }
}

export { formatStorage }
