import dayjs, { Dayjs } from 'dayjs'
import { formatTimeRange, nameOfDay } from '../'
import { FormatOptions } from '../format.types'
import { ITimeModeDay, ITimeModeEntity, ITimeModeWeekdays } from './interfaces'

/**
 * 获取某一天的时间模式
 * @param timeMode
 * @param date
 * @param locale
 * @returns
 */

export function getTimeModeForDay(timeMode: ITimeModeEntity, date: Dayjs, locale?: string) {
  const dateStr = date.format('YYYY-MM-DD')
  const dateOverride = timeMode.date_overrides.find((d) => d.date === dateStr)
  if (dateOverride) {
    return dateOverride.mode
  }

  const weekday = nameOfDay(date.weekday() as 0 | 1 | 2 | 3 | 4 | 5 | 6, {
    weekdayFormat: 'short',
    locale: locale
  })

  return timeMode.weekdays[weekday.toLowerCase() as keyof ITimeModeWeekdays]
}
/**
 * 显示一天的时间段,
 *
 * @example 'Closed' or '9:00 - 12:00, 12:00 - 17:00'
 *
 * @param weekday
 * @param options
 * @returns
 */
export function timeModeDayAsString(
  weekday: ITimeModeDay,
  options?: FormatOptions
) {
  if (weekday?.all_day_closed) {
    return 'Closed' // FIXME: use i18n
  }

  return (weekday?.segments || [])
    .map((segment) => {
      // const day = dayjs().format('YYYY-MM-DD')
      // const start = dayjs(`${day}, ${segment.start}`)
      // const end = dayjs(`${day}, ${segment.end}`)
      // return formatTimeRange([start, end], options)
      return `${segment.start.replace(/:00$/, '')} - ${segment.end.replace(/:00$/, '')}`
    })
    .join(', ')
}

/**
 * 显示每周的时间段, 例如 'Sunday: Closed; Monday: 09:00 - 17:00; Tuesday: 09:00 - 17:00; Wednesday: 09:00 - 17:00; Thursday: 09:00 - 17:00; Friday: 09:00 - 17:00; Saturday: Closed'
 *
 *
 * @param weekdays
 * @param options
 * @param firstDayOfWeek
 * @returns
 */
export function timeModeWeekDaysAsString(
  weekdays: ITimeModeWeekdays,
  options?: FormatOptions,
  firstDayOfWeek: number = 0 // Default to Sunday
) {
  const dayNames = [
    nameOfDay(0, options), // Sunday
    nameOfDay(1, options), // Monday
    nameOfDay(2, options), // Tuesday
    nameOfDay(3, options), // Wednesday
    nameOfDay(4, options), // Thursday
    nameOfDay(5, options), // Friday
    nameOfDay(6, options), // Saturday
  ]

  const timeStrings = [
    timeModeDayAsString(weekdays.sun, options),
    timeModeDayAsString(weekdays.mon, options),
    timeModeDayAsString(weekdays.tue, options),
    timeModeDayAsString(weekdays.wed, options),
    timeModeDayAsString(weekdays.thu, options),
    timeModeDayAsString(weekdays.fri, options),
    timeModeDayAsString(weekdays.sat, options),
  ]

  // Adjust the order of days and times based on the firstDayOfWeek
  const adjustedDayNames = dayNames
    .slice(firstDayOfWeek)
    .concat(dayNames.slice(0, firstDayOfWeek))
  const adjustedTimeStrings = timeStrings
    .slice(firstDayOfWeek)
    .concat(timeStrings.slice(0, firstDayOfWeek))

  let result = ''
  let i = 0

  while (i < 7) {
    const startDay = i
    let endDay = i

    // Find the range of consecutive days with the same time string
    while (
      endDay + 1 < 7 &&
      adjustedTimeStrings[endDay + 1] === adjustedTimeStrings[startDay]
    ) {
      endDay++
    }

    // Collect days in the current range
    const daysRange = []
    for (let j = startDay; j <= endDay; j++) {
      daysRange.push(adjustedDayNames[j])
    }

    // Format the range of days
    if (result) {
      result += '; '
    }

    if (daysRange.length === 1) {
      result += `${daysRange[0]}, ${adjustedTimeStrings[startDay]}`
    } else {
      // Merge consecutive days
      let consecutiveDays = `${daysRange[0]} - ${daysRange[daysRange.length - 1]}`
      result += `${consecutiveDays}, ${adjustedTimeStrings[startDay]}`
    }

    i = endDay + 1
  }

  return result
}

/**
 * 显示时间模式详情。目前只显示每周的，不包括节假日和特殊日期的显示。
 *
 * @example 'Sunday: Closed; Monday: 09:00 - 17:00; Tuesday: 09:00 - 17:00; Wednesday: 09:00 - 17:00; Thursday: 09:00 - 17:00; Friday: 09:00 - 17:00; Saturday: Closed'
 *
 * @param timeMode
 * @param options
 * @returns
 */
export function timeModeAsString(
  timeMode: ITimeModeEntity,
  options?: FormatOptions
) {
  return timeModeWeekDaysAsString(timeMode.weekdays, options)
}
