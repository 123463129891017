
import { BusinessTimeManager } from './businessTimeManager'
import dayjs, { Dayjs } from 'dayjs'
import { ITimeModeEntity } from './interfaces'
interface CacheKey {
  timeModeId: number
  timeZone: string
}

interface CacheEntry {
  businessTimeManager: BusinessTimeManager
  lastUsed: number
}

export class CachedBusinessTimeManager {
  private static cache: Map<string, CacheEntry> = new Map()
  private static nonWorkingTimeCache: Map<string, boolean> = new Map()
  private static MAX_CACHE_SIZE = 100
  private static CACHE_EXPIRY_TIME = 60 * 60 * 1000 // 1 hour in milliseconds

  private static getCacheKey(key: CacheKey): string {
    return `${key.timeModeId}:${key.timeZone}`
  }

  private static cleanCache() {
    const now = Date.now()
    for (const [key, entry] of this.cache.entries()) {
      if (now - entry.lastUsed > this.CACHE_EXPIRY_TIME) {
        this.cache.delete(key)
      }
    }
    if (this.cache.size > this.MAX_CACHE_SIZE) {
      const sortedEntries = Array.from(this.cache.entries()).sort(
        (a, b) => b[1].lastUsed - a[1].lastUsed
      )
      for (let i = this.MAX_CACHE_SIZE; i < sortedEntries.length; i++) {
        this.cache.delete(sortedEntries[i][0])
      }
    }
  }

  /**
   * 清除所有缓存
   */
  static clearCache(): void {
    this.cache.clear()
    this.nonWorkingTimeCache.clear()
  }

  /**
   * 清除指定时区的缓存
   *
   * @param timeZone
   */
  static clearTimeZoneCache(timeZone: string): void {
    for (const key of this.cache.keys()) {
      if (key.endsWith(`:${timeZone}`)) {
        this.cache.delete(key)
      }
    }
    for (const key of this.nonWorkingTimeCache.keys()) {
      const keys = key.split(':')
      if (keys?.[1] === timeZone) {
        this.nonWorkingTimeCache.delete(key)
      }
    }
  }

  static clearTimeModeCache(timeModeId: number): void {
    for (const key of this.cache.keys()) {
      if (key.startsWith(`${timeModeId}:`)) {
        this.cache.delete(key)
      }
    }
    for (const key of this.nonWorkingTimeCache.keys()) {
      const keys = key.split(':')
      if (keys?.[0] === timeModeId.toString()) {
        this.nonWorkingTimeCache.delete(key)
      }
    }
  }

  /**
   * 删除指定缓存
   *
   * @param timeModeId
   * @param timeZone
   */
  static deleteCacheEntry(timeModeId: number, timeZone: string): void {
    const cacheKey = this.getCacheKey({ timeModeId, timeZone })
    this.cache.delete(cacheKey)

    // Also remove any related entries from the nonWorkingTimeCache
    const prefix = `${cacheKey}:`
    for (const key of this.nonWorkingTimeCache.keys()) {
      if (key.startsWith(prefix)) {
        this.nonWorkingTimeCache.delete(key)
      }
    }
  }

  static getBusinessTimeManager(
    timeMode: ITimeModeEntity,
    timeZone: string
  ): BusinessTimeManager {
    const cacheKey = this.getCacheKey({ timeModeId: timeMode.id, timeZone })
    let cacheEntry = this.cache.get(cacheKey)

    if (!cacheEntry) {
      const businessTimeManager = new BusinessTimeManager(timeMode, timeZone)
      cacheEntry = { businessTimeManager, lastUsed: Date.now() }
      this.cache.set(cacheKey, cacheEntry)
      this.cleanCache()
    } else {
      cacheEntry.lastUsed = Date.now()
    }

    return cacheEntry.businessTimeManager
  }

  static isNonWorkingTime(
    timeMode: ITimeModeEntity,
    timeZone: string,
    time: string | Date | Dayjs,
    mode: 'Time' | 'FullDay' = 'Time'
  ): boolean {
    const timeJs = dayjs(time).second(0)
    const cacheKey = `${this.getCacheKey({ timeModeId: timeMode.id, timeZone })}:${timeJs.format()}:${mode}`

    if (this.nonWorkingTimeCache.has(cacheKey)) {
      return this.nonWorkingTimeCache.get(cacheKey)!
    }

    const businessTimeManager = this.getBusinessTimeManager(timeMode, timeZone)
    let result: boolean
    if (mode === 'Time') {
      result = !businessTimeManager.isInBusinessTime(timeJs)
    } else {
      result = businessTimeManager.isCloseDay(timeJs)
    }

    this.nonWorkingTimeCache.set(cacheKey, result)
    return result
  }
}
