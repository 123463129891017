export enum Frequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4,
  MINUTELY = 5,
  SECONDLY = 6,
}

//= 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU'
export enum WeekdayStr {
  MO,
  TU,
  WE,
  TH,
  FR,
  SA,
  SU,
}

export enum MonthStr {
  Jan = 1,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec
}

export interface RecurringEvent {
  /**
   * 开始时间
   */
  dtstart?: Date
  /**
   * 多久重复一次，和频率一起决定重复的间隔
   */
  interval: number
  /**
   * 重复次数
   */
  count: number | null
  /**
   * 频率
   */
  freq: Frequency
  /**
   * 结束时间
   */
  until?: Date | null
  /**
   * 时区
   */
  tzid?: string | null
  /**
   * 工作日
   */
  byweekday?: WeekdayStr[]

  /**
   * 第几次发生循环，配合byweekday使用可以设置每月第几周循环
   */
  bysetpos?: number[]

  /**
   * 每月第几号循环
   */
  bymonthday?: number[]

  /**
   * 月份
   */
  bymonth?: MonthStr[]
}
