import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timeZone from 'dayjs/plugin/timezone'
import { formatStorage } from '../formatStorage'
import { setToDate } from '../set'
import {
  BusinessUnitType,
  DAYS_OF_WEEK,
  ITimeModeDateOverride,
  ITimeModeEntity,
  ITimeModeWeekdays,
  ITimeSegment,
  TimeRange,
} from './interfaces'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(timeZone)

const DEFAULT_DAY_LIMIT = 365

enum DateFormat {
  date = 'YYYY-MM-DD',
  time = 'HH:mm:ss',
  dateTime = 'YYYY-MM-DD HH:mm:ss',
}

export class BusinessTimeManager {
  private readonly weekdays: ITimeModeWeekdays
  private readonly dateOverrides: { [date: string]: ITimeModeDateOverride }
  private dayLimit: number
  private timeZone: string
  private utcOffset: number

  constructor(timeModeEntity: ITimeModeEntity, timeZone?: string) {
    this.weekdays = timeModeEntity.weekdays
    this.dateOverrides = this.getDateOverridesFromEntity(
      timeModeEntity.date_overrides
    )
    this.dayLimit = DEFAULT_DAY_LIMIT
    this.timeZone = timeZone || formatStorage.getTimeZone() || dayjs.tz.guess()
    // console.log('timezone', this.timeZone)
    this.utcOffset = dayjs().tz(this.timeZone).utcOffset()
  }

  private getDateOverridesFromEntity(date_overrides: ITimeModeDateOverride[]): {
    [date: string]: ITimeModeDateOverride
  } {
    return date_overrides.reduce(
      (acc, override) => {
        acc[override.date] = override
        return acc
      },
      {} as { [date: string]: ITimeModeDateOverride }
    )
  }

  /**
   * 获得周几安排
   *
   * @returns
   */
  getWeekdays() {
    return this.weekdays
  }

  /**
   * 获得特殊日期安排
   * @returns
   */
  getDateOverrides() {
    return this.dateOverrides
  }

  /**
   * 获得日期计算最大值，默认365
   * @returns
   */
  getDayLimit() {
    return this.dayLimit
  }

  /**
   * 设置日期计算最大值，默认365
   *
   * @param dayLimit
   */
  setDayLimit(dayLimit: number) {
    this.dayLimit = dayLimit
  }

  /**
   * 是否是特殊日期
   *
   * @param date
   * @returns
   */
  isDateOverride(date: Dayjs): boolean {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    const formattedDate = timezoneDate.format(DateFormat.date)
    return this.dateOverrides.hasOwnProperty(formattedDate)
  }

  /**
   * Check if the day is open, that is, all_day_closed is false
   */
  isOpenDay(date: Dayjs): boolean {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    const formattedDate = timezoneDate.format(DateFormat.date)
    const override = this.dateOverrides[formattedDate]

    if (override) {
      return !override.mode.all_day_closed
    }

    const dayKey = DAYS_OF_WEEK[timezoneDate.day()] as keyof ITimeModeWeekdays
    return !this.getWeekdays()[dayKey]?.all_day_closed
  }

  /**
   * Check if the day is closed, that is, all_day_closed is true
   * @param date
   * @returns
   */
  isCloseDay(date: Dayjs): boolean {
    return !this.isOpenDay(date)
  }

  /**
   * 检查是否每天都关闭
   *
   * @param workDays
   * @returns
   */
  checkNoWorkDays(workDays: ITimeModeWeekdays): boolean {
    return Object.values(workDays).every((day) => day?.all_day_closed)
  }

  /**
   * 增加或减少工作时间
   *
   * @param date
   * @param numberOfDays
   * @param action
   * @returns
   */
  addOrSubtractBusinessDays(
    date: Dayjs,
    numberOfDays: number,
    action: 'add' | 'subtract' = 'add'
  ): Dayjs {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    let daysToIterate = numberOfDays
    let day = timezoneDate.clone()
    let dayLimit = this.getDayLimit()
    let dayCount = 0

    if (this.checkNoWorkDays(this.getWeekdays())) {
      const exceptions = this.getDateOverrides()
      if (exceptions && Object.values(exceptions).length > 0) {
        const { min, max } = this.getMinMaxDates(exceptions)
        const diff = dayjs(max).diff(min, 'days')
        dayLimit = Math.max(dayLimit, diff)
      }
    }

    while (daysToIterate) {
      day = day[action](1, 'day')
      if (this.isOpenDay(day)) {
        daysToIterate--
      }

      if (dayCount > dayLimit) {
        throw new Error(
          `No opening hours found in the ${action === 'add' ? 'next' : 'past'} ${dayLimit} days. Use setDayLimit() to increase day limit`
        )
      }
      dayCount++
    }

    return day
  }

  /**
   * 下一个工作日
   * @param date
   * @returns
   */
  nextBusinessDay(date: Dayjs): Dayjs {
    return this.addOrSubtractBusinessDays(date, 1, 'add')
  }

  /**
   * 上一个工作日
   * @param date
   * @returns
   */
  previousBusinessDay(date: Dayjs): Dayjs {
    return this.addOrSubtractBusinessDays(date, 1, 'subtract')
  }

  /**
   * 添加工作日
   * @param date
   * @param numberOfDays
   * @returns
   */
  addBusinessDays(date: Dayjs, numberOfDays: number): Dayjs {
    return this.addOrSubtractBusinessDays(date, numberOfDays, 'add')
      .set('hour', date.hour())
      .set('minute', date.minute())
      .set('second', date.second())
  }

  /**
   * 减去工作日
   *
   * @param date
   * @param numberOfDays
   * @returns
   */
  subtractBusinessDays(date: Dayjs, numberOfDays: number): Dayjs {
    return this.addOrSubtractBusinessDays(date, numberOfDays, 'subtract')
      .set('hour', date.hour())
      .set('minute', date.minute())
      .set('second', date.second())
  }

  _convertToTimeZoneIfNeeded(date: Dayjs): Dayjs {
    if (date.utcOffset() !== this.utcOffset) {
      return dayjs.tz(date, this.timeZone)
    }
    return date
  }

  /**
   * Check if a time range is within open hour time segments.
   * @param startTime
   * @param endTime
   * @returns
   */
  isWithinOpenHour(startTime: Dayjs, endTime: Dayjs): boolean {
    const timezoneStartTime = this._convertToTimeZoneIfNeeded(startTime)
    const timezoneEndTime = this._convertToTimeZoneIfNeeded(endTime)
    if (
      !this.isInBusinessTime(timezoneStartTime) ||
      !this.isInBusinessTime(timezoneEndTime)
    ) {
      return false
    }

    let currentTime = timezoneStartTime.clone()

    while (currentTime.isBefore(timezoneEndTime)) {
      const currentSegment = this.getCurrentBusinessTimeSegment(currentTime)

      if (!currentSegment) {
        return false
      }

      const [segmentStart, segmentEnd] = currentSegment

      // Check if endTime is within the current segment
      if (timezoneEndTime.isSameOrBefore(segmentEnd)) {
        return true
      }

      // Move to the next business time segment
      currentTime = segmentEnd.add(1, 'second')
      const nextSegment = this.getCurrentBusinessTimeSegment(currentTime)

      // If there's a gap to the next segment, return false
      if (!nextSegment || !segmentEnd.isSame(nextSegment[0])) {
        return false
      }
    }

    return false
  }

  /**
   * Get time segments for the specified day, return undefined if the day is closed.
   *
   * 注意：如果最后一段endTime是00:00:00，则表示跨天，会自动替换endTime为24:00:00
   *
   * @example
   * 9:00:00 - 17:00:00 => 9:00:00 - 17:00:00
   * 18:00:00 - 00:00:00 => 18:00:00 - 24:00:00
   *
   * @param day
   * @returns
   */
  getBusinessTimeSegments(day: Dayjs): ITimeSegment[] | undefined {
    const timezoneDay = this._convertToTimeZoneIfNeeded(day)
    const dayKey = DAYS_OF_WEEK[timezoneDay.day()] as keyof ITimeModeWeekdays
    const formattedDate = timezoneDay.format(DateFormat.date)
    const businessHours = this.isDateOverride(timezoneDay)
      ? this.getDateOverrides()[formattedDate]?.mode
      : this.getWeekdays()[dayKey]
    // console.log(">> getBusinessTimeSegments", day.format('YYYY-MM-DD'), dayKey, businessHours)
    if (!businessHours) return undefined

    let segments = businessHours.segments?.sort((a, b) =>
      dayjs(a.start).isAfter(dayjs(b.start)) ? -1 : 1
    )
    // if last segment is 00:00:00, then it means the business time is across the next day, replace it with 24:00:00
    if (segments?.[segments.length - 1]?.end === '00:00:00') {
      segments[segments.length - 1].end = '24:00:00'
    }
    return segments
  }

  /**
   * Get the time segment at the exact time of the specified day, return undefined if not opened.
   *
   * @param date
   * @returns
   */
  getCurrentBusinessTimeSegment(date: Dayjs): TimeRange | undefined {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    const businessSegments = this.getBusinessTimeSegments(timezoneDate)
    if (!businessSegments?.length) {
      return undefined
    }

    const timezoneTimeStr = timezoneDate.format(DateFormat.time)
    const segment = businessSegments.find((segment) => {
      const { start, end } = segment
      let endTime = end
      // 因为getDateWithTimeString性能较差，所以这里直接使用时间字符串进行比较
      // const startTime = this.getDateWithTimeString(timezoneDate, start)
      // let endTime = this.getDateWithTimeString(timezoneDate, end)
      // 如果end是00:00:00，则表示跨天，需要加1天
      if (end < start) {
        // endTime = this.getDateWithTimeString(timezoneDate.add(1, 'day'), end)
        endTime = '24:00:00'
      }
      // [9, 17), 包括开始时间，不包括结束时间
      return (
        // timezoneDate.isSameOrAfter(startTime) &&
        // timezoneDate.isSameOrBefore(endTime)
        timezoneTimeStr >= start && timezoneTimeStr <= endTime
      )
    })
    if (!segment) {
      return undefined
    }

    // console.log(
    //   '>> getCurrentBusinessTimeSegment',
    //   date.format(),
    //   segment,
    //   businessSegments
    // )

    return this.getTimeSegmentsWithDate(timezoneDate, segment)
  }

  /**
   * 根据日期返回当天的开始和结束时间
   * @param day
   * @returns
   */
  getBusinessStartEndTime(day: Dayjs): TimeRange | undefined {
    const businessSegments = this.getBusinessTimeSegments(day)
    if (!businessSegments?.length) return

    const startTime = this.getDateWithTimeString(day, businessSegments[0].start)
    const endTime = this.getDateWithTimeString(day, businessSegments[businessSegments.length - 1].end)

    return [startTime, endTime]
  }

  /**
   * 获取当天的开始时间
   * @param day
   * @returns
   */
  getBusinessStartTime(day: Dayjs): Dayjs | undefined {
    const businessSegments = this.getBusinessTimeSegments(day)
    if (!businessSegments?.length) return

    return this.getDateWithTimeString(day, businessSegments[0].start)
  }

  /**
   * 获取当天的结束时间
   * @param day
   * @returns
   */
  getBusinessEndTime(day: Dayjs): Dayjs | undefined {
    const businessSegments = this.getBusinessTimeSegments(day)
    if (!businessSegments?.length) return

    return this.getDateWithTimeString(day, businessSegments[businessSegments.length - 1].end)
  }

  /**
   * Check if the time is in business time, that is, is open.
   *
   * 注意：使用exact的时间进行判断的，即该时间是否是工作时间。
   *
   * @param date
   * @returns
   */
  isInBusinessTime(date: Dayjs): boolean {
    return !!this.getCurrentBusinessTimeSegment(date)
  }

  /**
   * Get next business time segment after the given time. If date is in a segment, this will return the next segment. use getCurrentBusinessTimeSegment to return current segment
   *
   * @param date the exact time
   * @returns TimeRange of the open segment
   */
  nextBusinessTimeSegment(date: Dayjs): TimeRange {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    if (!this.isOpenDay(timezoneDate)) {
      const nextBusinessDay = this.nextBusinessDay(timezoneDate)
      const timeSegments = this.getBusinessTimeSegments(nextBusinessDay)
      if (!timeSegments?.length) {
        throw new Error(
          `Time segments cannot be empty on next business day: ${nextBusinessDay.format(DateFormat.date)}`
        )
      }
      return this.getTimeSegmentsWithDate(nextBusinessDay, timeSegments[0])
    }

    const segments = this.getBusinessTimeSegments(timezoneDate)
    if (!segments?.length) {
      throw new Error(
        `Time segments cannot be empty on current business day: ${timezoneDate.format(DateFormat.date)}`
      )
    }
    for (let index = 0; index < segments.length; index++) {
      const segment = segments[index]
      const { start, end } = segment

      if (
        timezoneDate.isBefore(this.getDateWithTimeString(timezoneDate, start))
      ) {
        // found the segment
        return this.getTimeSegmentsWithDate(timezoneDate, segments[index])
      }

      const isLastSegment = index === segments.length - 1
      if (timezoneDate.isAfter(this.getDateWithTimeString(timezoneDate, end))) {
        if (!isLastSegment) {
          continue
        }

        // if last segment of today, go to next business day
        const nextBusinessDay = this.nextBusinessDay(timezoneDate)
        const timeSegments = this.getBusinessTimeSegments(nextBusinessDay)
        if (!timeSegments?.length) {
          throw new Error(
            `Time segments cannot be empty on next business day: ${nextBusinessDay.format(DateFormat.date)}`
          )
        }
        return this.getTimeSegmentsWithDate(nextBusinessDay, timeSegments[0])
      }
    }
    throw new Error(
      `No Time segments were found that're later than given on current business day or future: ${date.format(DateFormat.date)}`
    )
  }

  /**
   * Get previous business time segment before the given time. If date is in a segment, this will return the previous segment. use getCurrentBusinessTimeSegment to return current segment
   *
   * @param date
   * @returns
   */
  previousBusinessTimeSegment(date: Dayjs): TimeRange {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    if (!this.isOpenDay(timezoneDate)) {
      const lastBusinessDay = this.previousBusinessDay(timezoneDate)
      const timeSegments = this.getBusinessTimeSegments(lastBusinessDay)
      if (!timeSegments?.length) {
        throw new Error(
          `Time segments cannot be empty on last business day: ${lastBusinessDay.format(DateFormat.date)}`
        )
      }
      const timeSegment = timeSegments?.[timeSegments.length - 1]
      if (!timeSegment) {
        throw new Error(
          `Time segments cannot be empty on last business day: ${lastBusinessDay.format(DateFormat.date)}`
        )
      }
      return this.getTimeSegmentsWithDate(lastBusinessDay, timeSegment)
    }

    let segments = this.getBusinessTimeSegments(timezoneDate)
    if (!segments?.length) {
      throw new Error(
        `Time segments cannot be empty on current business day: ${timezoneDate.format(DateFormat.date)}`
      )
    }

    segments = segments.reverse()

    for (let index = 0; index < segments.length; index++) {
      const segment = segments[index]
      const { start, end } = segment
      const isFirstSegment = index === segments.length - 1

      if (timezoneDate.isAfter(this.getDateWithTimeString(timezoneDate, end))) {
        // found the segment
        return this.getTimeSegmentsWithDate(timezoneDate, segment)
      }

      if (
        timezoneDate.isBefore(this.getDateWithTimeString(timezoneDate, start))
      ) {
        if (!isFirstSegment) {
          continue
        }

        const lastBusinessDay = this.previousBusinessDay(timezoneDate)
        const timeSegments = this.getBusinessTimeSegments(lastBusinessDay)
        if (!timeSegments?.length) {
          throw new Error(
            `Time segments cannot be empty on last business day: ${lastBusinessDay.format(DateFormat.date)}`
          )
        }
        const timeSegment = timeSegments?.[timeSegments.length - 1]
        if (!timeSegment) {
          throw new Error(
            `Time segments cannot be empty on last business day: ${lastBusinessDay.format(DateFormat.date)}`
          )
        }
        return this.getTimeSegmentsWithDate(lastBusinessDay, timeSegment)
      }
    }
    throw new Error(
      `No Time segments were found that're earlier than given on current business day or before: ${date.format(DateFormat.date)}`
    )
  }

  /**
   * Add business seconds
   * @param date
   * @param secondsToAdd
   * @returns
   */
  addBusinessSeconds(date: Dayjs, secondsToAdd: number): Dayjs {
    return this.addOrSubtractBusinessSeconds(date, secondsToAdd, 'add')
  }

  /**
   * Add business minutes
   * @param date
   * @param minutesToAdd
   * @returns
   */
  addBusinessMinutes(date: Dayjs, minutesToAdd: number): Dayjs {
    return this.addOrSubtractBusinessMinutes(date, minutesToAdd, 'add').set(
      'second',
      date.second()
    )
  }

  /**
   * Add business hours
   * @param date
   * @param hoursToAdd
   * @returns
   */
  addBusinessHours(date: Dayjs, hoursToAdd: number): Dayjs {
    const minutesToAdd = hoursToAdd * 60
    return this.addBusinessMinutes(date, minutesToAdd)
      .set('minute', date.minute())
      .set('second', date.second())
  }

  /**
   * Add business time
   */
  addBusinessTime(
    date: Dayjs,
    timeToAdd: number,
    businessUnit: BusinessUnitType
  ): Dayjs {
    if (businessUnit.match(/^(second)+s?$/)) {
      return this.addBusinessSeconds(date, timeToAdd)
    }

    if (businessUnit.match(/^(minute)+s?$/)) {
      return this.addBusinessMinutes(date, timeToAdd)
    }

    if (businessUnit.match(/^(hour)+s?$/)) {
      return this.addBusinessHours(date, timeToAdd)
    }

    if (businessUnit.match(/^(day)+s?$/)) {
      return this.addBusinessDays(date, timeToAdd)
    }

    throw new Error('Invalid Business Time Unit')
  }

  /**
   * Add or subtract business minutes from given date
   * @param date
   * @param numberOfMinutes
   * @param action
   * @returns
   */
  addOrSubtractBusinessMinutes(
    date: Dayjs,
    numberOfMinutes: number,
    action: 'add' | 'subtract' = 'add'
  ): Dayjs {
    let dateInstance = date

    while (numberOfMinutes) {
      const segmentTimeRange = this.getCurrentBusinessTimeSegment(dateInstance)

      if (!segmentTimeRange) {
        dateInstance =
          action === 'add'
            ? this.nextBusinessTimeSegment(dateInstance)[0]
            : this.previousBusinessTimeSegment(dateInstance)[0]
        continue
      }

      const [start, end] = segmentTimeRange

      const compareBaseDate = action === 'add' ? end : dateInstance
      const compareDate = action === 'add' ? dateInstance : start

      let timeToJump = compareBaseDate.diff(compareDate, 'minute')

      if (timeToJump > numberOfMinutes) {
        timeToJump = numberOfMinutes
      }

      numberOfMinutes -= timeToJump

      if (!timeToJump && numberOfMinutes) {
        timeToJump = 1
      }

      dateInstance = dateInstance[action](timeToJump, 'minute')
    }

    return dateInstance.set('second', date.second())
  }

  /**
   * Add or subtract business seconds
   * @param date
   * @param numberOfSeconds
   * @param action
   * @returns
   */
  addOrSubtractBusinessSeconds(
    date: Dayjs,
    numberOfSeconds: number,
    action: 'add' | 'subtract' = 'add'
  ): Dayjs {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    let dateInstance = timezoneDate

    while (numberOfSeconds) {
      const segmentTimeRange = this.getCurrentBusinessTimeSegment(dateInstance)

      if (!segmentTimeRange) {
        dateInstance =
          action === 'add'
            ? this.nextBusinessTimeSegment(dateInstance)[0]
            : this.previousBusinessTimeSegment(dateInstance)[0]
        continue
      }

      const [start, end] = segmentTimeRange

      const compareBaseDate =
        action === 'add' ? this._convertToTimeZoneIfNeeded(end) : dateInstance
      const compareDate =
        action === 'add' ? dateInstance : this._convertToTimeZoneIfNeeded(start)

      let timeToJump = compareBaseDate.diff(compareDate, 'second')

      if (timeToJump > numberOfSeconds) {
        timeToJump = numberOfSeconds
      }

      numberOfSeconds -= timeToJump

      if (!timeToJump && numberOfSeconds) {
        timeToJump = 1
      }

      dateInstance = dateInstance[action](timeToJump, 'second')
    }

    return dateInstance
  }

  /**
   * subtract business minutes
   * @param date
   * @param minutesToSubtract
   * @returns
   */
  subtractBusinessMinutes(date: Dayjs, minutesToSubtract: number): Dayjs {
    return this.addOrSubtractBusinessMinutes(
      date,
      minutesToSubtract,
      'subtract'
    )
  }

  /**
   * subtract business hours
   * @param date
   * @param hoursToSubtract
   * @returns
   */
  subtractBusinessHours(date: Dayjs, hoursToSubtract: number): Dayjs {
    const minutesToSubtract = hoursToSubtract * 60
    return this.subtractBusinessMinutes(date, minutesToSubtract)
      .set('minute', date.minute())
      .set('second', date.second())
  }

  /**
   * subtract business time
   * @param date
   * @param timeToSubtract
   * @param businessUnit
   * @returns
   */
  subtractBusinessTime(
    date: Dayjs,
    timeToSubtract: number,
    businessUnit: BusinessUnitType
  ): Dayjs {
    if (businessUnit.match(/^(minute)+s?$/)) {
      return this.subtractBusinessMinutes(date, timeToSubtract)
    }

    if (businessUnit.match(/^(hour)+s?$/)) {
      return this.subtractBusinessHours(date, timeToSubtract)
    }

    if (businessUnit.match(/^(day)+s?$/)) {
      return this.subtractBusinessDays(date, timeToSubtract)
    }

    throw new Error('Invalid Business Time Unit')
  }

  /**
   * check base and comparator and change its order based on the sequence, returns from and to time with 1,-1 as multiplier
   * @param base
   * @param comparator
   * @returns
   */
  private fixDatesToCalculateDiff(
    base: Dayjs,
    comparator: Dayjs,
    unit: 'day' | 'hour' | 'minute' | 'second'
  ) {
    const timezoneBase = this._convertToTimeZoneIfNeeded(base)
    const timezoneComparator = this._convertToTimeZoneIfNeeded(comparator)
    let from: Dayjs = timezoneBase.clone()
    let to: Dayjs = timezoneComparator.clone()
    let multiplier = 1

    // compare before and after
    if (timezoneBase.isAfter(timezoneComparator)) {
      to = timezoneBase.clone()
      from = timezoneComparator.clone()
      multiplier = -1
    }

    if (unit === 'day') {
      if (!this.isOpenDay(from)) {
        from = this.previousBusinessTimeSegment(from)[0]
      }

      if (!this.isOpenDay(to)) {
        to = this.nextBusinessTimeSegment(to)[0]
      }

      return { from, to, multiplier }
    }

    if (!this.isInBusinessTime(from)) {
      from = this.nextBusinessTimeSegment(from)[0]
    }

    if (!this.isInBusinessTime(to)) {
      to = this.nextBusinessTimeSegment(to)[0]
    }

    return { from, to, multiplier }
  }

  /**
   * Compare the number of business days between two days
   * @param base
   * @param comparator
   * @returns
   */
  businessDaysDiff(base: Dayjs, comparator: Dayjs): number {
    const timezoneBase = this._convertToTimeZoneIfNeeded(base)
    const timezoneComparator = this._convertToTimeZoneIfNeeded(comparator)
    let { from, to, multiplier } = this.fixDatesToCalculateDiff(
      timezoneBase,
      timezoneComparator,
      'day'
    )
    let diff = 0
    while (!from.isSame(to, 'day')) {
      diff += 1
      from = this.addBusinessDays(from, 1)
    }
    return diff ? diff * multiplier : 0
  }

  /**
   * Compare the number of business minutes between two days
   * @param base
   * @param comparator
   * @returns
   */
  businessMinutesDiff(base: Dayjs, comparator: Dayjs): number {
    const timezoneBase = this._convertToTimeZoneIfNeeded(base)
    const timezoneComparator = this._convertToTimeZoneIfNeeded(comparator)
    let { from, to, multiplier } = this.fixDatesToCalculateDiff(
      timezoneBase,
      timezoneComparator,
      'minute'
    )
    let diff = 0

    const isSameDayFromTo = from.isSame(to, 'day')
    if (isSameDayFromTo) {
      const fromSegments = this.getBusinessTimeSegments(from) || []
      for (const segment of fromSegments) {
        const { start, end } = segment
        const fromTimeStr = from.format(DateFormat.time)
        const toTimeStr = to.format(DateFormat.time)

        // const startTime = this.getDateWithTimeString(from, start);
        // let endTime = this.getDateWithTimeString(from, end);
        // if (end < start) {
        //   endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
        // }
        if (
          toTimeStr >= start &&
          toTimeStr <= end &&
          fromTimeStr >= start &&
          fromTimeStr <= end
        ) {
          diff += to.diff(from, 'minutes')
          break
        } else if (
          toTimeStr >= start &&
          toTimeStr <= end
        ) {
          const startTime = this.getDateWithTimeString(from, start);
          diff += to.diff(startTime, 'minutes')
          break
        } else if (
          fromTimeStr >= start &&
          fromTimeStr <= end
        ) {
          let endTime
          if (end < start) {
            endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
          } else {
            endTime = this.getDateWithTimeString(from, end);
          }
          diff += endTime.diff(from, 'minutes')
        }
      }

      return diff ? diff * multiplier : 0
    }

    let segments = this.getBusinessTimeSegments(from) || []

    for (const segment of segments) {
      const { start, end } = segment
      const fromTimeStr = from.format(DateFormat.time)
      const toTimeStr = to.format(DateFormat.time)
      const startTime = this.getDateWithTimeString(from, start);
      let endTime = this.getDateWithTimeString(from, end);
      if (end < start) {
        endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
      }
      if (
        fromTimeStr >= start &&
        fromTimeStr <= end
      ) {
        diff += endTime.diff(from, 'minutes')
      } else if (start >= fromTimeStr) {
        diff += endTime.diff(startTime, 'minutes')
      }
    }

    from = this.addBusinessDays(from, 1)
    while (from.isBefore(to, 'day')) {
      segments = this.getBusinessTimeSegments(from) || []
      for (const segment of segments) {
        const { start, end } = segment
        const startTime = this.getDateWithTimeString(from, start);
        let endTime = this.getDateWithTimeString(from, end);
        if (end < start) {
          endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
        }
        diff += endTime.diff(startTime, 'minutes')
      }

      from = this.addBusinessDays(from, 1)
    }

    const toSegments = this.getBusinessTimeSegments(to) || []
    for (const segment of toSegments) {
      const { start, end } = segment
      const startTime = this.getDateWithTimeString(to, start);
      let endTime = this.getDateWithTimeString(to, end);
      if (end < start) {
        endTime = this.getDateWithTimeString(to.add(1, 'day'), end)
      }
      if (
        to.isSameOrAfter(startTime) &&
        to.isSameOrBefore(endTime)
      ) {
        diff += to.diff(startTime, 'minutes')
      } else if (endTime.isSameOrBefore(to)) {
        diff += endTime.diff(startTime, 'minutes')
      }
    }

    return diff ? diff * multiplier : 0
  }

  /**
   * Compare the number of business seconds between two days
   * @param base
   * @param comparator
   * @returns
   */
  businessSecondsDiff(base: Dayjs, comparator: Dayjs): number {
    const timezoneBase = this._convertToTimeZoneIfNeeded(base)
    const timezoneComparator = this._convertToTimeZoneIfNeeded(comparator)
    let { from, to, multiplier } = this.fixDatesToCalculateDiff(
      timezoneBase,
      timezoneComparator,
      'second'
    )
    let diff = 0

    const isSameDayFromTo = from.isSame(to, 'day')
    if (isSameDayFromTo) {
      const fromSegments = this.getBusinessTimeSegments(from) || []
      for (const segment of fromSegments) {
        const { start, end } = segment
        const startTime = this.getDateWithTimeString(from, start);
        let endTime = this.getDateWithTimeString(from, end);
        if (end < start) {
          endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
        }
        if (
          to.isSameOrAfter(startTime) &&
          to.isSameOrBefore(endTime) &&
          from.isSameOrAfter(startTime) &&
          from.isSameOrBefore(endTime)
        ) {
          diff += to.diff(from, 'seconds')
          break
        } else if (
          to.isSameOrAfter(startTime) &&
          to.isSameOrBefore(endTime)
        ) {
          diff += to.diff(startTime, 'seconds')
          break
        } else if (
          from.isSameOrAfter(startTime) &&
          from.isSameOrBefore(endTime)
        ) {
          diff += endTime.diff(from, 'seconds')
        }
      }

      return diff ? diff * multiplier : 0
    }

    let segments = this.getBusinessTimeSegments(from) || []
    for (const segment of segments) {
      const { start, end } = segment
      const startTime = this.getDateWithTimeString(from, start);
      let endTime = this.getDateWithTimeString(from, end);
      if (end < start) {
        endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
      }
      if (
        from.isSameOrAfter(startTime) &&
        from.isSameOrBefore(endTime)
      ) {
        diff += this.getDateWithTimeString(from, end).diff(from, 'seconds')
      } else if (startTime.isSameOrAfter(from)) {
        diff += endTime.diff(startTime, 'seconds')
      }
    }

    from = this.addBusinessDays(from, 1)
    while (from.isBefore(to, 'day')) {
      segments = this.getBusinessTimeSegments(from) || []
      for (const segment of segments) {
        const { start, end } = segment
        const startTime = this.getDateWithTimeString(from, start);
        let endTime = this.getDateWithTimeString(from, end);
        if (end < start) {
          endTime = this.getDateWithTimeString(from.add(1, 'day'), end)
        }
        diff += endTime.diff(startTime, 'seconds')
      }

      from = this.addBusinessDays(from, 1)
    }

    const toSegments = this.getBusinessTimeSegments(to) || []
    for (const segment of toSegments) {
      const { start, end } = segment
      const startTime = this.getDateWithTimeString(to, start);
      let endTime = this.getDateWithTimeString(to, end);
      if (end < start) {
        endTime = this.getDateWithTimeString(to.add(1, 'day'), end)
      }
      if (
        to.isSameOrAfter(startTime) &&
        to.isSameOrBefore(endTime)
      ) {
        diff += to.diff(startTime, 'seconds')
      } else if (endTime.isSameOrBefore(to)) {
        diff += endTime.diff(startTime, 'seconds')
      }
    }

    return diff ? diff * multiplier : 0
  }

  /**
   * Compare the number of business hours between two days
   * @param base
   * @param comparator
   * @returns
   */
  businessHoursDiff(base: Dayjs, comparator: Dayjs): number {
    const minutesDiff = this.businessMinutesDiff(base, comparator)
    return minutesDiff / 60
  }

  /**
   * Compare the number of business time between two days
   * @param base
   * @param comparator
   * @param businessUnit
   * @returns
   */
  businessTimeDiff(
    base: Dayjs,
    comparator: Dayjs,
    businessUnit: BusinessUnitType
  ): number {
    if (businessUnit.match(/^(second)+s?$/)) {
      return this.businessSecondsDiff(base, comparator)
    }

    if (businessUnit.match(/^(minute)+s?$/)) {
      return this.businessMinutesDiff(base, comparator)
    }

    if (businessUnit.match(/^(hour)+s?$/)) {
      return this.businessHoursDiff(base, comparator)
    }

    if (businessUnit.match(/^(day)+s?$/)) {
      return this.businessDaysDiff(base, comparator)
    }

    throw new Error('Invalid Business Time Unit')
  }

  /**
   * 合并时间段
   *
   * @param array
   * @returns
   */
  mergeOverlappingIntervals(array: ITimeSegment[]): ITimeSegment[] {
    // Sort the array by the start time
    const sortedArray = array.sort((a, b) =>
      dayjs(a.start).isAfter(dayjs(b.start)) ? 1 : -1
    )

    // Initialize the result array
    const result = []

    for (let i = 0; i < sortedArray.length; i++) {
      const currentInterval = sortedArray[i]
      const start = dayjs(currentInterval.start)
      const end = dayjs(currentInterval.end)
      const endOfDay = dayjs('24:00:00')

      // If the start time is greater than the end time, skip this interval
      if (start.isAfter(end, 'second')) {
        continue
      }

      // If the start time is greater than or equal to the end of day, skip this interval
      if (start.isSameOrAfter(endOfDay, 'second')) {
        continue
      }

      // custom start
      // if end >= endOfDay, set end to 24:00:00
      currentInterval.start = start.format(DateFormat.time)
      if (end.isSameOrAfter(endOfDay, 'second')) {
        currentInterval.end = '24:00:00'
      } else {
        currentInterval.end = end.format(DateFormat.time)
      }

      const lastInterval = result[result.length - 1]
      let lastEnd = null
      if (lastInterval && lastInterval?.end) {
        lastEnd = dayjs(lastInterval.end)
      }

      if (
        result.length === 0 ||
        (lastEnd && start.isAfter(lastEnd, 'second'))
      ) {
        // If the current interval does not overlap, add it to the result array
        result.push(currentInterval)
      } else if (lastEnd && end.isAfter(lastEnd, 'second')) {
        // If the current interval overlaps with the last interval, merge them
        lastInterval.end = currentInterval.end
      }
    }

    return result as ITimeSegment[]
  }

  /**
   * Get min and max dates in the array, returned as "YYYY-MM-DD" format
   * @param obj
   * @returns
   */
  private getMinMaxDates(obj: { [date: string]: ITimeModeDateOverride }): {
    min: string
    max: string
  } {
    const dates = Object.keys(obj)
    const minDate = new Date(
      Math.min(...dates.map((date) => new Date(date).getTime()))
    )
    const maxDate = new Date(
      Math.max(...dates.map((date) => new Date(date).getTime()))
    )

    return {
      min: minDate.toISOString().split('T')[0],
      max: maxDate.toISOString().split('T')[0],
    }
  }

  /**
   * Convert date and `HH:MM:SS` to new date with the time
   *
   * @param date
   * @param time
   * @returns
   */
  private getDateWithTimeString(date: Dayjs, time: string): Dayjs {
    const timezoneDate = this._convertToTimeZoneIfNeeded(date)
    const timeDate = dayjs(time, 'HH:mm:ss')
    // console.log('getDateWithTimeString', date.format(), time, timeDate.format())
    // 如果不加一天，那么时间会变成date一天的开始时间
    if (time === '24:00:00') {
      return setToDate(timeDate, timezoneDate.add(1, 'day'))
    } else {
      return setToDate(timeDate, timezoneDate)
    }
    // return dayjs(`${date.format(DateFormat.date)} ${time}`);
  }

  /***
   * Convert time segments to dayjs array
   *
   * @returns [Start, End]
   */
  private getTimeSegmentsWithDate(
    date: Dayjs,
    timeSegment: ITimeSegment
  ): [Dayjs, Dayjs] {
    let startTime = this.getDateWithTimeString(date, timeSegment.start)
    let endTime = this.getDateWithTimeString(date, timeSegment.end)
    while (endTime.isBefore(startTime, 'second')) {
      endTime = endTime.add(1, 'day')
    }
    return [startTime, endTime]

    // timeSegment.end < timeSegment.start 表示跨天
    // if (timeSegment.end <= timeSegment.start || timeSegment.end >= '24:00:00') {
    //   return [
    //     this.getDateWithTimeString(date, timeSegment.start),
    //     this.getDateWithTimeString(date.add(1, 'day'), timeSegment.end),
    //   ]
    // }
    // return [
    //   this.getDateWithTimeString(date, timeSegment.start),
    //   this.getDateWithTimeString(date, timeSegment.end),
    // ]
  }
}
