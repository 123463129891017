
export interface Timezone {
  value: string
  abbreviation: string
  altName: string
}
/**
 * List of essential timezones, such as GMT, UTC, and other major cities.
 */
export const ESSENTIAL_TIME_ZONES: Timezone[] = [
  {
    value: 'Etc/GMT+12',
    altName: 'International Date Line West',
    abbreviation: 'DST',

  },
  {
    value: 'Etc/GMT+11',
    altName: 'Coordinated Universal Time-11',
    abbreviation: 'UTC-11',

  },
  { value: 'Pacific/Honolulu', altName: 'Hawaii', abbreviation: 'HST', },
  { value: 'America/Anchorage', altName: 'Alaska', abbreviation: 'AKST', },
  {
    value: 'America/Tijuana',
    altName: 'Baja California',
    abbreviation: 'PST',

  },
  {
    value: 'America/Los_Angeles',
    altName: 'Pacific Time (US & Canada)',
    abbreviation: 'PST',

  },
  { value: 'America/Phoenix', altName: 'Arizona', abbreviation: 'MST', },
  {
    value: 'America/Chihuahua',
    altName: 'Chihuahua, La Paz, Mazatlan',
    abbreviation: 'MST',

  },
  {
    value: 'America/Denver',
    altName: 'Mountain Time (US & Canada)',
    abbreviation: 'MST',

  },
  {
    value: 'America/Guatemala',
    altName: 'Central America',
    abbreviation: 'CST',

  },
  {
    value: 'America/Chicago',
    altName: 'Central Time (US & Canada)',
    abbreviation: 'CST',

  },
  {
    value: 'America/Mexico_City',
    altName: 'Guadalajara, Mexico City, Monterrey',
    abbreviation: 'CST',

  },
  { value: 'America/Regina', altName: 'Saskatchewan', abbreviation: 'CST', },
  {
    value: 'America/Bogota',
    altName: 'Bogota, Lima, Quito',
    abbreviation: 'COT',

  },
  {
    value: 'America/New_York',
    altName: 'Eastern Time (US & Canada)',
    abbreviation: 'EST',

  },
  {
    value: 'America/Indiana/Indianapolis',
    altName: 'Indiana (East)',
    abbreviation: 'EST',

  },
  { value: 'America/Caracas', altName: 'Caracas', abbreviation: 'VET', },
  { value: 'America/Asuncion', altName: 'Asuncion', abbreviation: 'PYST', },
  {
    value: 'America/Halifax',
    altName: 'Atlantic Time (Canada)',
    abbreviation: 'AST',

  },
  { value: 'America/Cuiaba', altName: 'Cuiaba', abbreviation: 'CBT', },
  {
    value: 'America/La_Paz',
    altName: 'Georgetown, La Paz, Manaus, San Juan',
    abbreviation: 'SAWST',

  },
  { value: 'America/Santiago', altName: 'Santiago', abbreviation: 'PSAST', },
  { value: 'America/St_Johns', altName: 'Newfoundland', abbreviation: 'NST', },
  { value: 'America/Sao_Paulo', altName: 'Brasilia', abbreviation: 'ESAST', },
  {
    value: 'America/Argentina/Buenos_Aires',
    altName: 'Buenos Aires',
    abbreviation: 'ART',

  },
  {
    value: 'America/Cayenne',
    altName: 'Cayenne, Fortaleza',
    abbreviation: 'SAEST',

  },
  { value: 'America/Godthab', altName: 'Greenland', abbreviation: 'GST', },
  { value: 'America/Montevideo', altName: 'Montevideo', abbreviation: 'MST', },
  { value: 'America/Bahia', altName: 'Salvador', abbreviation: 'BST', },
  {
    value: 'Etc/GMT+2',
    altName: 'Coordinated Universal Time-02',
    abbreviation: 'UTC-02',

  },
  { value: 'Atlantic/Azores', altName: 'Azores', abbreviation: 'AST', },
  {
    value: 'Atlantic/Cape_Verde',
    altName: 'Cape Verde Is.',
    abbreviation: 'CVST',

  },
  { value: 'Africa/Casablanca', altName: 'Casablanca', abbreviation: 'MST', },
  {
    value: 'Etc/UTC',
    altName: 'Coordinated Universal Time',
    abbreviation: 'UTC',

  },
  {
    value: 'Europe/London',
    altName: 'Dublin, Edinburgh, Lisbon, London',
    abbreviation: 'GMT',

  },
  {
    value: 'Atlantic/Reykjavik',
    altName: 'Monrovia, Reykjavik',
    abbreviation: 'GST',

  },
  {
    value: 'Europe/Berlin',
    altName: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    abbreviation: 'WEST',

  },
  {
    value: 'Europe/Budapest',
    altName: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    abbreviation: 'CEST',

  },
  {
    value: 'Europe/Paris',
    altName: 'Brussels, Copenhagen, Madrid, Paris',
    abbreviation: 'RST',

  },
  {
    value: 'Europe/Warsaw',
    altName: 'Sarajevo, Skopje, Warsaw, Zagreb',
    abbreviation: 'CEST',

  },
  {
    value: 'Africa/Lagos',
    altName: 'West Central Africa',
    abbreviation: 'WCAST',

  },
  { value: 'Africa/Windhoek', altName: 'Windhoek', abbreviation: 'NST', },
  {
    value: 'Europe/Bucharest',
    altName: 'Athens, Bucharest',
    abbreviation: 'GTBST',

  },
  { value: 'Asia/Beirut', altName: 'Beirut', abbreviation: 'MEST', },
  { value: 'Africa/Cairo', altName: 'Cairo', abbreviation: 'EST', },
  { value: 'Asia/Damascus', altName: 'Damascus', abbreviation: 'SST', },
  {
    value: 'Africa/Johannesburg',
    altName: 'Harare, Pretoria',
    abbreviation: 'SAST',

  },
  {
    value: 'Europe/Kiev',
    altName: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    abbreviation: 'FLEST',

  },
  { value: 'Europe/Istanbul', altName: 'Istanbul', abbreviation: 'TST', },
  { value: 'Asia/Jerusalem', altName: 'Jerusalem', abbreviation: 'IST', },
  { value: 'Africa/Tripoli', altName: 'Tripoli', abbreviation: 'LST', },
  { value: 'Asia/Amman', altName: 'Amman', abbreviation: 'JST', },
  { value: 'Asia/Baghdad', altName: 'Baghdad', abbreviation: 'AST', },
  { value: 'Europe/Kaliningrad', altName: 'Kaliningrad', abbreviation: 'KST', },
  { value: 'Asia/Riyadh', altName: 'Kuwait, Riyadh', abbreviation: 'AST', },
  { value: 'Africa/Nairobi', altName: 'Nairobi', abbreviation: 'EAST', },
  { value: 'Asia/Tehran', altName: 'Tehran', abbreviation: 'IRST', },
  { value: 'Asia/Dubai', altName: 'Abu Dhabi, Muscat', abbreviation: 'AST', },
  { value: 'Asia/Baku', altName: 'Baku', abbreviation: 'AZST', },
  { value: 'Indian/Mauritius', altName: 'Port Louis', abbreviation: 'MST', },
  { value: 'Asia/Tbilisi', altName: 'Tbilisi', abbreviation: 'GET', },
  { value: 'Asia/Yerevan', altName: 'Yerevan', abbreviation: 'CST', },
  { value: 'Asia/Kabul', altName: 'Kabul', abbreviation: 'AFST', },
  {
    value: 'Asia/Tashkent',
    altName: 'Ashgabat, Tashkent',
    abbreviation: 'WAST',

  },
  {
    value: 'Asia/Karachi',
    altName: 'Islamabad, Karachi',
    abbreviation: 'PKST',

  },
  {
    value: 'Asia/Kolkata',
    altName: 'Chennai, Kolkata, Mumbai, New Delhi',
    abbreviation: 'IST',

  },
  {
    value: 'Asia/Colombo',
    altName: 'Sri Jayawardenepura',
    abbreviation: 'SLST',

  },
  { value: 'Asia/Kathmandu', altName: 'Kathmandu', abbreviation: 'NST', },
  { value: 'Asia/Almaty', altName: 'Astana', abbreviation: 'CAST', },
  { value: 'Asia/Dhaka', altName: 'Dhaka', abbreviation: 'BST', },
  { value: 'Asia/Yangon', altName: 'Yangon (Rangoon)', abbreviation: 'MST', },
  {
    value: 'Asia/Bangkok',
    altName: 'Bangkok, Hanoi, Jakarta',
    abbreviation: 'SEAST',

  },
  { value: 'Asia/Novosibirsk', altName: 'Novosibirsk', abbreviation: 'NCAST', },
  {
    value: 'Asia/Shanghai',
    altName: 'Beijing, Chongqing, Hong Kong, Urumqi',
    abbreviation: 'CST',

  },
  { value: 'Asia/Krasnoyarsk', altName: 'Krasnoyarsk', abbreviation: 'NAST', },
  {
    value: 'Asia/Singapore',
    altName: 'Kuala Lumpur, Singapore',
    abbreviation: 'SST',

  },
  { value: 'Australia/Perth', altName: 'Perth', abbreviation: 'WAST', },
  { value: 'Asia/Taipei', altName: 'Taipei', abbreviation: 'TST', },
  { value: 'Asia/Ulaanbaatar', altName: 'Ulaanbaatar', abbreviation: 'ULAT', },
  { value: 'Asia/Irkutsk', altName: 'Irkutsk', abbreviation: 'NAEST', },
  {
    value: 'Asia/Tokyo',
    altName: 'Osaka, Sapporo, Tokyo',
    abbreviation: 'JST',

  },
  { value: 'Asia/Seoul', altName: 'Seoul', abbreviation: 'KST', },
  { value: 'Australia/Adelaide', altName: 'Adelaide', abbreviation: 'CAST', },
  { value: 'Australia/Darwin', altName: 'Darwin', abbreviation: 'ACST', },
  { value: 'Australia/Brisbane', altName: 'Brisbane', abbreviation: 'EAST', },
  {
    value: 'Australia/Sydney',
    altName: 'Canberra, Melbourne, Sydney',
    abbreviation: 'AEST',

  },
  {
    value: 'Pacific/Port_Moresby',
    altName: 'Guam, Port Moresby',
    abbreviation: 'WPST',

  },
  { value: 'Australia/Hobart', altName: 'Hobart', abbreviation: 'TST', },
  { value: 'Asia/Yakutsk', altName: 'Yakutsk', abbreviation: 'YST', },
  {
    value: 'Pacific/Guadalcanal',
    altName: 'Solomon Is., New Caledonia',
    abbreviation: 'CPST',

  },
  { value: 'Asia/Vladivostok', altName: 'Vladivostok', abbreviation: 'VST', },
  {
    value: 'Pacific/Auckland',
    altName: 'Auckland, Wellington',
    abbreviation: 'NZST',

  },
  {
    value: 'Etc/GMT-12',
    altName: 'Coordinated Universal Time+12',
    abbreviation: 'UTC+12',

  },
  { value: 'Pacific/Fiji', altName: 'Fiji', abbreviation: 'FST', },
  { value: 'Asia/Magadan', altName: 'Magadan', abbreviation: 'MST', },
  {
    value: 'Asia/Kamchatka',
    altName: 'Petropavlovsk-Kamchatsky - Old',
    abbreviation: 'KST',

  },
  { value: 'Pacific/Tongatapu', altName: "Nuku'alofa", abbreviation: 'TST', },
  { value: 'Pacific/Apia', altName: 'Samoa', abbreviation: 'SST', }
]
